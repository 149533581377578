import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './app/store';
import { useMediaQuery, useTheme } from '@mui/material';
import Navbar from './components/Navbar';
import { generateUserDeviceId } from './app/businessSlice';
import ReactGA from 'react-ga4';
import { DesktopView } from './views/DesktopView';
import { MobileView } from './views/MobileView';

ReactGA.initialize('G-KZFV4YCKW5', { testMode: true });

function App() {
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useAppDispatch();
  const userDeviceId = useAppSelector((state) => state.business.userDeviceId);

  useEffect(() => {
    console.log('userDeviceId', userDeviceId);
    if (!userDeviceId) dispatch(generateUserDeviceId());
    else {
      console.log('Setting userId...');
      ReactGA.set({ userId: userDeviceId });
    }
  }, [dispatch, userDeviceId]);

  return (
    <>
      <Navbar />
      {isDesktopScreen ? <DesktopView /> : <MobileView />}
    </>
  );
}

export default App;
