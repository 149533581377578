import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CalculationsDrawer from '../components/CalculationsDrawer';
import MeRow from '../components/MeRow';
import MeStatsRow from '../components/MeStatsRow';
import DownlineRow from '../components/DownlineRow';
import { useAppDispatch, useAppSelector } from '../app/store';
import { addDownline, setShowEditSavedHistoryName } from '../app/businessSlice';
import useAnalyticsEventTracker, {
  AnalyticsCategories,
} from '../hooks/useAnalytics';
import { useMemo } from 'react';

export const MobileView = () => {
  const dispatch = useAppDispatch();
  const downlineCount = useAppSelector(
    (state) => state.business.downline.length
  );
  const businessEventTracker = useAnalyticsEventTracker(
    AnalyticsCategories.Business
  );
  const savedHistories = useAppSelector(
    (state) => state.business.savedConfigurations
  );
  const selectedHistoryId = useAppSelector(
    (state) => state.business.selectedConfigurationId
  );

  const selectedHistory = useMemo(() => {
    if (!selectedHistoryId) return null;

    return savedHistories.find((sh) => sh.id === selectedHistoryId);
  }, [savedHistories.length, selectedHistoryId]);

  const indexArray = Array.from(Array(downlineCount).keys());

  return (
    <Grid
      item
      container
      direction="column"
      sx={{ px: 2, margin: 0, height: '100%' }}
      rowSpacing={2}
    >
      {selectedHistory && (
        <Grid item xs={1}>
          <Stack
            direction="row"
            alignItems="center"
            bgcolor="#102a43"
            px={3}
            py={0.5}
            borderRadius={3}
          >
            <Typography>
              {`Viewing: `}
              <Typography
                display="inline"
                fontStyle="italic"
                sx={{ textDecoration: 'underline' }}
              >
                {selectedHistory.name}
              </Typography>
            </Typography>

            {/* <IconButton
              onClick={() => {
                console.log('setting edit saved history name dialog oepn');
                dispatch(setShowEditSavedHistoryName(true));
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton> */}
          </Stack>
        </Grid>
      )}

      <Grid item xs={1}>
        <MeRow />
      </Grid>

      <Grid item xs={1}>
        <MeStatsRow />
      </Grid>

      <Grid item xs={8} sx={{ overflowY: 'scroll', maxHeight: '33vh' }}>
        <Stack spacing={2}>
          {indexArray.map((i) => (
            <DownlineRow index={i} key={i} />
          ))}
        </Stack>
      </Grid>

      <Grid item xs={2}>
        <Button
          variant="contained"
          onClick={() => {
            businessEventTracker('AddDownline', {});
            dispatch(addDownline());
          }}
          style={{ height: 45, minWidth: '100%' }}
        >
          Add Leg
        </Button>
      </Grid>

      <CalculationsDrawer />
    </Grid>
  );
};
