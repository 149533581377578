import { Stack, Divider, Typography } from '@mui/material';
import useCalculations from '../hooks/useCalculations';
import MoneyRow from './MoneyRow';
import SumRow from './SumRow';
import { currency } from '../utils/helperFunctions';

type SummaryDetailsProps = {
  includeTotalIncome: boolean;
};

export default function SummaryDetails({
  includeTotalIncome,
}: SummaryDetailsProps) {
  const {
    personalBonus,
    retailProfit,
    totalDifferentialBonus,
    differentialBonuses,
    bronzeFoundationBonus,
    bronzeBuilderBonus,
    totalBonus,
    totalIncome,
  } = useCalculations();

  return (
    <>
      <Stack
        paddingX={1}
        paddingBottom={includeTotalIncome ? 5 : 0}
        spacing={6}
      >
        <Stack>
          <MoneyRow label="Personal Bonus" money={personalBonus} />
          <MoneyRow label="Retail Profit" money={retailProfit} />
          <Divider
            sx={{ marginTop: 1, borderBottomWidth: 2, bgcolor: 'white' }}
          />
          <SumRow label="Total Personal" sum={personalBonus + retailProfit} />
        </Stack>

        <Stack>
          {differentialBonuses.map((d, index) => (
            <MoneyRow
              key={index}
              label={`${d.name || `IBO ${index + 1}`} (${d.groupPv})`}
              money={d.bonus}
            />
          ))}
          {differentialBonuses.length <= 0 && (
            <Typography sx={{ fontStyle: 'italic' }}>
              No Downline Added
            </Typography>
          )}
          <Divider
            sx={{
              marginTop: 1,
              borderBottomWidth: 2,
              bgcolor: 'white',
            }}
          />
          <SumRow label="Total Differential" sum={totalDifferentialBonus} />
        </Stack>

        <Stack>
          <MoneyRow label="Bronze Foundation" money={bronzeFoundationBonus} />
          <MoneyRow label="Bronze Builder" money={bronzeBuilderBonus} />
          <Divider
            sx={{ marginTop: 1, borderBottomWidth: 2, bgcolor: 'white' }}
          />
          <SumRow label="Total Bonuses" sum={totalBonus} />
        </Stack>
      </Stack>

      {includeTotalIncome && (
        <Stack
          bgcolor="green"
          direction="row"
          justifyContent="space-between"
          padding={1}
          borderRadius={1}
        >
          <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
            Total Income:
          </Typography>
          <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
            {currency(totalIncome)}
          </Typography>
        </Stack>
      )}
    </>
  );
}
