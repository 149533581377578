import { useCallback, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../app/store';
import {
  Stack,
  TextField,
  Typography,
  styled,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { setDownline } from '../app/businessSlice';
import { getPerformanceBonus } from '../utils/helperFunctions';
import { AVG_PV_BV_RATIO } from '../utils/constants';
import { RowBackground } from './HelperComponents';
import useGroupStats from '../hooks/useGroupStats';
import DownlineMoreButton from './DownlineMoreButton';
import useAnalyticsEventTracker, {
  AnalyticsCategories,
} from '../hooks/useAnalytics';

const Input = styled(TextField)(({ theme }) => ({
  minWidth: '100%',
}));

type DownlineRowProps = {
  index: number;
};

export default function DownlineRow({ index }: DownlineRowProps) {
  const [previousName, setPreviousName] = useState('');
  const [previousGroupPv, setPreviousGroupPv] = useState(-1);
  const [previousGroupBv, setPreviousGroupBv] = useState(-1);
  const { performanceBonus } = useGroupStats();

  const hasNamedChanged = previousName !== '';
  const hasGpvChanged = previousGroupPv !== -1;
  const hasGbvChanged = previousGroupBv !== -1;

  const { name, groupPv, groupBv } = useAppSelector(
    (state) => state.business.downline[index]
  );
  const dispatch = useAppDispatch();

  const downlinePerformanceBonus = getPerformanceBonus(groupPv);
  const businessEventTracker = useAnalyticsEventTracker(
    AnalyticsCategories.Business
  );

  const handleChange = useCallback(
    (index: number, property: string, value: string | number) => {
      dispatch(
        setDownline({
          index,
          property,
          value,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    handleChange(index, 'groupBv', groupPv * AVG_PV_BV_RATIO);
  }, [index, groupPv, handleChange]);

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <RowBackground variant="elevation" elevation={10}>
      <Stack direction="row" columnGap={1}>
        <Typography
          variant="h5"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {index + 1}
        </Typography>

        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={8} sm={4}>
            <Input
              label="IBO Name"
              size={isBigScreen ? 'medium' : 'small'}
              value={name}
              onChange={(e) => {
                if (!hasNamedChanged) setPreviousName(name);
                handleChange(index, 'name', e.target.value);
              }}
              onBlur={() => {
                if (hasNamedChanged) {
                  businessEventTracker('ChangeText', {
                    index,
                    label: 'Name',
                    value: name,
                    previousValue: previousName,
                  });
                  setPreviousName('');
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <Input
              label="GPV"
              size={isBigScreen ? 'medium' : 'small'}
              value={groupPv}
              onChange={(e) => {
                if (!hasGpvChanged) setPreviousGroupPv(groupPv);
                handleChange(index, 'groupPv', parseFloat(e.target.value) || 0);
              }}
              onBlur={() => {
                if (hasGpvChanged) {
                  businessEventTracker('ChangeText', {
                    index,
                    label: 'Group PV',
                    value: groupPv,
                    previousValue: previousGroupPv,
                  });
                  setPreviousGroupPv(-1);
                }
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <Input
              label="GBV"
              size={isBigScreen ? 'medium' : 'small'}
              value={groupBv}
              onChange={(e) => {
                if (!hasGbvChanged) setPreviousGroupBv(groupBv);
                handleChange(index, 'groupBv', parseFloat(e.target.value) || 0);
              }}
              onBlur={() => {
                if (hasGbvChanged) {
                  businessEventTracker('ChangeText', {
                    index,
                    label: 'Group PV',
                    value: groupBv,
                    previousValue: previousGroupBv,
                  });
                  setPreviousGroupBv(-1);
                }
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <Input
              label="Perf %"
              size={isBigScreen ? 'medium' : 'small'}
              value={downlinePerformanceBonus}
              disabled
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <Input
              label="Diff %"
              size={isBigScreen ? 'medium' : 'small'}
              value={performanceBonus - downlinePerformanceBonus}
              disabled
            />
          </Grid>
        </Grid>

        <DownlineMoreButton index={index} groupPv={groupPv} />
      </Stack>
    </RowBackground>
  );
}
