import { Paper, styled, Grid } from '@mui/material';
import useGroupStats from '../hooks/useGroupStats';
import { RowBackground } from './HelperComponents';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontWeight: 'bold',
  width: '100%',
  height: '100%',
}));

export default function MeStatsRow() {
  const { numOfDownline, groupPv, performanceBonus } = useGroupStats();

  return (
    <RowBackground sx={{ background: 'gray' }}>
      {/* <Paper style={{ padding: 20, background: 'gray' }}> */}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Item># Legs: {numOfDownline}</Item>
        </Grid>
        <Grid item xs={4}>
          <Item>Group PV: {groupPv}</Item>
        </Grid>
        <Grid item xs={4}>
          <Item>Perf Bonus: {performanceBonus}%</Item>
        </Grid>
      </Grid>
    </RowBackground>
  );
}
