import { Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import SavedHistory from './SavedHistory';

type SavedHistoryDrawerProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};
export default function SavedHistoryDrawer(props: SavedHistoryDrawerProps) {
  return (
    <>
      <Drawer
        anchor="left"
        open={props.isOpen}
        onClose={() => {
          props.setIsOpen(false);
        }}
      >
        <Box sx={{ width: 250 }} role="presentation">
          <SavedHistory setIsOpen={props.setIsOpen} />
        </Box>
      </Drawer>
    </>
  );
}
