import { useAppSelector } from '../app/store';
import { getProfit } from '../utils/stats';
import useGroupStats from './useGroupStats';

export default function useCalculations() {
  const { performanceBonus } = useGroupStats();
  const isBronzeFoundationActive = useAppSelector(
    (state) => state.business.bonuses.isBronzeFoundationActive
  );
  const isBronzeBuilderActive = useAppSelector(
    (state) => state.business.bonuses.isBronzeBuilderActive
  );
  const bonuses = { isBronzeFoundationActive, isBronzeBuilderActive };

  const personalBv = useAppSelector((state) => state.business.me.personalBv);
  const vcsPv = useAppSelector((state) => state.business.me.vcsPv);
  const downline = useAppSelector((state) => state.business.downline);

  const {
    personalBonus,
    retailProfit,
    totalDifferentialBonus,
    differentialBonuses,
    bronzeFoundationBonus,
    bronzeBuilderBonus,
    totalBonus,
    totalIncome,
  } = getProfit(personalBv, vcsPv, performanceBonus, downline, bonuses);

  return {
    personalBonus,
    retailProfit,
    totalDifferentialBonus,
    differentialBonuses,
    bronzeFoundationBonus,
    bronzeBuilderBonus,
    totalBonus,
    totalIncome,
  };
}
