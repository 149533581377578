import {
  Badge,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import HistoryIcon from '@mui/icons-material/History';
import MobileBronzeSwitches from './MobileBronzeSwitches';
import { useState } from 'react';
import SavedHistoryDrawer from './SavedHistoryDrawer';
import { useAppSelector } from '../app/store';

export default function Navbar() {
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [isOpen, setIsOpen] = useState(false);
  const numOfSavedHistories = useAppSelector(
    (state) => state.business.savedConfigurations.length
  );

  return (
    <>
      <AppBar>
        <Toolbar sx={{ alignItems: 'center' }}>
          {!isDesktopScreen && (
            <Box pr={1}>
              <IconButton
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <Badge
                  color={numOfSavedHistories ? 'primary' : undefined}
                  variant={!numOfSavedHistories ? 'dot' : undefined}
                  badgeContent={numOfSavedHistories}
                  showZero={!numOfSavedHistories}
                >
                  <HistoryIcon />
                </Badge>
              </IconButton>
            </Box>
          )}

          <Typography variant="h6">Income Estimator</Typography>

          <Box flexGrow={1} />

          {!isDesktopScreen && <MobileBronzeSwitches />}
        </Toolbar>
      </AppBar>
      <Toolbar />

      <SavedHistoryDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
