import { Paper } from '@mui/material';
import { styled } from '@mui/system';

export const RowBackground = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    padding: 20,
  },
  padding: 15,
  maxWidth: '100vw',
}));
