import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, TextField, useTheme, useMediaQuery } from '@mui/material';
import { setMe } from '../app/businessSlice';
import { styled } from '@mui/system';
import { RowBackground } from './HelperComponents';
import { AVG_PV_BV_RATIO } from '../utils/constants';

const Input = styled(TextField)(({ theme }) => ({
  minWidth: '100%',
}));

export default function MeRow() {
  const { name, personalPv, vcsPv, personalBv } = useSelector(
    (state: any) => state.business.me
  );
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (property: string, value: string | number) => {
      dispatch(setMe({ property, value }));
    },
    [dispatch]
  );

  useEffect(() => {
    handleChange('personalBv', (personalPv + vcsPv) * AVG_PV_BV_RATIO);
  }, [personalPv, vcsPv, handleChange]);

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <RowBackground variant="elevation" elevation={10}>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={8} sm={4}>
          <Input
            label="My Name"
            size={isBigScreen ? 'medium' : 'small'}
            value={name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Input
            label="PPV"
            size={isBigScreen ? 'medium' : 'small'}
            value={personalPv}
            onChange={(e) =>
              handleChange('personalPv', parseFloat(e.target.value) || 0)
            }
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Input
            label="VCS PV"
            size={isBigScreen ? 'medium' : 'small'}
            value={vcsPv}
            onChange={(e) =>
              handleChange('vcsPv', parseFloat(e.target.value) || 0)
            }
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Input
            label="Total PV"
            size={isBigScreen ? 'medium' : 'small'}
            value={personalPv + vcsPv}
            disabled
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Input
            label="PBV"
            size={isBigScreen ? 'medium' : 'small'}
            value={personalBv}
            onChange={(e) =>
              handleChange('personalBv', parseFloat(e.target.value) || 0)
            }
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>
      </Grid>
      {/* </Paper> */}
    </RowBackground>
  );
}
