import { Stack, Typography } from '@mui/material';
import { currency } from '../utils/helperFunctions';

type MoneyRowProps = {
  label: string;
  money: number;
};

export default function MoneyRow({ label, money }: MoneyRowProps) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography style={{ fontSize: 20 }}>{label}:</Typography>
      <Typography style={{ fontSize: 20 }}>{currency(money)}</Typography>
    </Stack>
  );
}
