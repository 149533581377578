import { Stack, Typography } from '@mui/material';
import { currency } from '../utils/helperFunctions';

type SumRowProps = {
  label: string;
  sum: number;
};

export default function SumRow({ label, sum }: SumRowProps) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
        {label}:
      </Typography>
      <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
        {currency(sum)}
      </Typography>
    </Stack>
  );
}
