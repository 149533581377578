import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { calculateGroupPv, getProfit } from '../utils/stats';
import { getPerformanceBonus } from '../utils/helperFunctions';

export type BusinessTree = {
  me: MePersonalCircle;
  downline: Downline[];
  bonuses: Bonuses;
};

export type SavedConfiguration = {
  id: string;
  name: string;
  savedAt: string; // ISO format
  totalIncome: number;
  me: MePersonalCircle;
  downline: Downline[];
  bonuses: Bonuses;
};

export type MePersonalCircle = {
  name: '';
  personalPv: number;
  vcsPv: number;
  personalBv: number;
};

export type Downline = {
  name: string;
  groupPv: number;
  groupBv: number;
  performanceBonus: number;
  differentialBonus: number;
};

export type Bonuses = {
  isBronzeFoundationActive: boolean;
  isBronzeBuilderActive: boolean;
};

const initialDownline: Downline = {
  name: '',
  groupPv: 150,
  groupBv: 0,
  performanceBonus: 0,
  differentialBonus: 0,
};

const initialState = {
  userDeviceId: '',
  showEditSavedHistoryName: false,
  me: {
    name: '',
    personalPv: 50,
    vcsPv: 100,
    personalBv: 0,
  } as MePersonalCircle,
  downline: [] as Downline[],
  bonuses: {
    isBronzeFoundationActive: false,
    isBronzeBuilderActive: false,
  } as Bonuses,

  previousBusinessTree: {} as BusinessTree,
  selectedConfigurationId: null as string | null,
  savedConfigurations: [] as SavedConfiguration[],
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    generateUserDeviceId: (state) => {
      state.userDeviceId = uuidv4();
    },
    setShowEditSavedHistoryName: (state, action) => {
      state.showEditSavedHistoryName = action.payload;
    },
    setMe: (state, action) => {
      const { property, value } = action.payload;
      // @ts-ignore
      state.me[property] = value;
    },
    addDownline: (state) => {
      state.downline.push(initialDownline);
    },
    setDownline: (state, action) => {
      const { index, property, value } = action.payload;
      const selectedDownline = state.downline[index];
      // @ts-ignore
      selectedDownline[property] = value;
    },
    removeDownline: (state, action) => {
      const index = action.payload;
      state.downline.splice(index, 1);

      const threePercenters = state.downline.filter((d) => d.groupPv > 150);
      const sixPercenters = state.downline.filter((d) => d.groupPv > 300);

      if (threePercenters.length < 3)
        state.bonuses.isBronzeFoundationActive = false;
      if (sixPercenters.length < 3) state.bonuses.isBronzeBuilderActive = false;
    },
    setIsBronzeFoundationActive: (state, action) => {
      state.bonuses.isBronzeFoundationActive = action.payload;
    },
    setIsBronzeBuilderActive: (state, action) => {
      state.bonuses.isBronzeBuilderActive = action.payload;
    },
    toggleBronzeFoundation: (state) => {
      if (state.bonuses.isBronzeFoundationActive === true) {
        state.bonuses.isBronzeFoundationActive = false;
      } else {
        state.bonuses.isBronzeFoundationActive = true;
      }
    },
    toggleBronzeBuilder: (state) => {
      if (state.bonuses.isBronzeBuilderActive === true) {
        state.bonuses.isBronzeBuilderActive = false;
      } else {
        state.bonuses.isBronzeBuilderActive = true;
      }
    },

    saveCurrentConfiguration: (state) => {
      const numOfLegs = state.downline.length;
      const gpv = calculateGroupPv(
        state.me.personalPv + state.me.vcsPv,
        state.downline.map((d) => d.groupPv)
      );

      const { totalIncome } = getProfit(
        state.me.personalBv,
        state.me.vcsPv,
        getPerformanceBonus(gpv),
        state.downline,
        state.bonuses
      );

      const savedConfiguration: SavedConfiguration = {
        id: uuidv4(),
        name: `${numOfLegs} legs / ${gpv}PV`,
        savedAt: new Date().toISOString(),
        totalIncome: totalIncome,
        me: state.me,
        downline: state.downline,
        bonuses: state.bonuses,
      };

      state.savedConfigurations.unshift(savedConfiguration);
    },
    removeConfiguration: (state, action) => {
      const idToRemove = action.payload.id;

      const newArr = state.savedConfigurations.filter(
        (s) => s.id !== idToRemove
      );
      state.savedConfigurations = newArr;

      if (state.selectedConfigurationId === idToRemove) {
        state.selectedConfigurationId = null;
        state.me = state.previousBusinessTree.me;
        state.downline = state.previousBusinessTree.downline;
        state.bonuses = state.previousBusinessTree.bonuses;

        state.previousBusinessTree = initialState.previousBusinessTree;
      }
    },
    resetCurrentConfiguration: (state) => {
      state.me = initialState.me;
      state.downline = initialState.downline;
      state.bonuses = initialState.bonuses;
    },

    setSelectedConfiguration: (state, action) => {
      const index = action.payload.index;
      const savedConfig = state.savedConfigurations.at(index);

      if (!savedConfig) return;

      state.selectedConfigurationId = savedConfig.id;
      state.previousBusinessTree = {
        me: state.me,
        downline: state.downline,
        bonuses: state.bonuses,
      };

      state.me = savedConfig.me;
      state.downline = savedConfig.downline;
      state.bonuses = savedConfig.bonuses;
    },

    backToCurrentConfiguration: (state) => {
      state.selectedConfigurationId = null;
      state.me = state.previousBusinessTree.me;
      state.downline = state.previousBusinessTree.downline;
      state.bonuses = state.previousBusinessTree.bonuses;

      state.previousBusinessTree = initialState.previousBusinessTree;
    },
    updateConfigurationName: (state, action) => {
      const newName = action.payload.name;
      const index = action.payload.index;

      state.savedConfigurations[index].name = newName;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  generateUserDeviceId,
  setShowEditSavedHistoryName,
  setMe,
  addDownline,
  setDownline,
  removeDownline,
  toggleBronzeFoundation,
  toggleBronzeBuilder,
  setIsBronzeFoundationActive,
  setIsBronzeBuilderActive,
  saveCurrentConfiguration,
  removeConfiguration,
  resetCurrentConfiguration,
  setSelectedConfiguration,
  backToCurrentConfiguration,
  updateConfigurationName,
} = businessSlice.actions;

export default businessSlice.reducer;
