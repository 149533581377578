import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import businessReducer from './businessSlice';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

export const persistedReducer = persistReducer(persistConfig, businessReducer);

export const store = configureStore({
  reducer: {
    business: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// export const useAppSelector: (store: RootState) => any = useSelector;

// export const persistor = persistStore(store);
