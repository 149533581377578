import { Paper, SwipeableDrawer, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box, styled } from '@mui/system';
import { useState } from 'react';
import useCalculations from '../hooks/useCalculations';
import { currency } from '../utils/helperFunctions';
import SummaryDetails from './SummaryDetails';

const DRAWER_BLEEDING = 75;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#333',
}));

const Puller = () => (
  <Box
    sx={{
      width: 30,
      height: 6,
      backgroundColor: (theme) =>
        theme.palette.mode === 'light' ? grey[300] : grey[900],
      borderRadius: 3,
      position: 'absolute',
      top: 8,
      left: 'calc(50% - 15px)',
    }}
  />
);

export default function BottomDrawer() {
  const [open, setOpen] = useState(false);
  const { totalIncome } = useCalculations();

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableSwipeToOpen={false}
        swipeAreaWidth={DRAWER_BLEEDING}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          style: {
            height: `calc(50% - ${DRAWER_BLEEDING}px)`,
          },
          sx: {
            overflow: 'visible',
          },
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -DRAWER_BLEEDING,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Box
            sx={{
              height: DRAWER_BLEEDING,
              paddingLeft: 3,
              paddingRight: 3,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
            }}
          >
            <Typography variant="h4" color="white" gutterBottom>
              Income
            </Typography>
            <Typography variant="h4" color="green" gutterBottom>
              {currency(totalIncome)}
            </Typography>
          </Box>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Paper
            sx={{
              backgroundColor: '#0000001c',
              height: '100%',
              overflowY: 'scroll',
              width: '100%',
              color: 'white',
            }}
          >
            <Box
              sx={{
                padding: 1,
                paddingBottom: 2,
              }}
            >
              <SummaryDetails includeTotalIncome={false} />
            </Box>
          </Paper>
        </StyledBox>
      </SwipeableDrawer>
    </>
  );
}
