import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@mui/material';
import moment from 'moment';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import MeRow from '../components/MeRow';
import MeStatsRow from '../components/MeStatsRow';
import DownlineRow from '../components/DownlineRow';
import {
  addDownline,
  toggleBronzeFoundation,
  toggleBronzeBuilder,
  resetCurrentConfiguration,
  saveCurrentConfiguration,
  removeConfiguration,
  setSelectedConfiguration,
  backToCurrentConfiguration,
  updateConfigurationName,
} from '../app/businessSlice';
import SummaryDetails from '../components/SummaryDetails';
import { bronzeColor } from '../utils/theme';
import { useAppDispatch, useAppSelector } from '../app/store';
import useBonuses from '../hooks/useBonuses';
import DeleteIcon from '@mui/icons-material/Delete';
import { currency } from '../utils/helperFunctions';
import useCalculations from '../hooks/useCalculations';
import useGroupStats from '../hooks/useGroupStats';
import { useEffect, useState } from 'react';
import useAnalyticsEventTracker, {
  AnalyticsCategories,
} from '../hooks/useAnalytics';
import SavedHistory from '../components/SavedHistory';

export const DesktopView = () => {
  const dispatch = useAppDispatch();

  const downlineCount = useAppSelector(
    (state) => state.business.downline.length
  );
  const isBronzeFoundationActive = useAppSelector(
    (state) => state.business.bonuses.isBronzeFoundationActive
  );
  const isBronzeBuilderActive = useAppSelector(
    (state) => state.business.bonuses.isBronzeBuilderActive
  );

  const { isBronzeFoundationEnabled, isBronzeBuilderEnabled } = useBonuses();

  const bonusesEventTracker = useAnalyticsEventTracker(
    AnalyticsCategories.Bonuses
  );
  const businessEventTracker = useAnalyticsEventTracker(
    AnalyticsCategories.Business
  );

  const indexArray = Array.from(Array(downlineCount).keys());

  return (
    <>
      <Stack
        direction="row"
        spacing={{
          md: 2,
          lg: 3,
          xl: 5,
        }}
      >
        <SavedHistory />

        <Stack
          width={{
            md: '47%',
            lg: '52%',
            xl: '60%',
          }}
          spacing={4}
          pt={2}
        >
          <MeRow />
          <MeStatsRow />

          <Stack spacing={2}>
            {indexArray.map((i) => (
              <DownlineRow index={i} key={i} />
            ))}
          </Stack>

          <Button
            variant="contained"
            onClick={() => {
              businessEventTracker('AddDownline', {});
              dispatch(addDownline());
            }}
            style={{ height: 45, minWidth: '100%' }}
          >
            Add Leg
          </Button>
        </Stack>

        <Stack
          width={{
            md: '33%',
            lg: '30%',
            xl: '25%',
          }}
          spacing={4}
          pt={2}
          pr={2}
        >
          <Card raised>
            <CardHeader
              avatar={<NewReleasesIcon color="success" fontSize="large" />}
              title="Bronze Incentives"
              titleTypographyProps={{ color: bronzeColor, variant: 'h4' }}
            />
            <CardContent>
              <Stack
                paddingX={1}
                direction="row"
                justifyContent="space-between"
              >
                <Tooltip
                  title={
                    isBronzeFoundationEnabled === true
                      ? ''
                      : 'You must have 3 legs at 150 PV AND 600 GPV!'
                  }
                >
                  <FormGroup>
                    <FormControlLabel
                      label="Foundation"
                      control={
                        <Switch
                          disabled={!isBronzeFoundationEnabled}
                          onChange={() => {
                            bonusesEventTracker('ToggleBronzeFoundation', {
                              value: isBronzeFoundationActive
                                ? 'Disabled'
                                : 'Enabled',
                            });
                            dispatch(toggleBronzeFoundation());
                          }}
                          checked={isBronzeFoundationActive}
                        />
                      }
                    />
                  </FormGroup>
                </Tooltip>

                <Tooltip
                  title={
                    isBronzeBuilderEnabled === true
                      ? ''
                      : 'You must have 3 legs at 300 PV AND 2500 GPV!'
                  }
                >
                  <FormGroup>
                    <FormControlLabel
                      label="Builder"
                      control={
                        <Switch
                          disabled={!isBronzeBuilderEnabled}
                          onChange={() => {
                            bonusesEventTracker('ToggleBronzeBuilder', {
                              value: isBronzeBuilderActive
                                ? 'Disabled'
                                : 'Enabled',
                            });
                            dispatch(toggleBronzeBuilder());
                          }}
                          checked={isBronzeBuilderActive}
                        />
                      }
                    />
                  </FormGroup>
                </Tooltip>
              </Stack>
            </CardContent>
          </Card>

          <Card raised>
            <CardHeader
              title="Income"
              titleTypographyProps={{ color: 'green', variant: 'h4' }}
            />
            <CardContent>
              <SummaryDetails includeTotalIncome={true} />
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </>
  );
};
