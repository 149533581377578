import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/store';
import {
  setIsBronzeBuilderActive,
  setIsBronzeFoundationActive,
} from '../app/businessSlice';
import useGroupStats from './useGroupStats';

function useBonuses() {
  const dispatch = useAppDispatch();
  const { groupPv } = useGroupStats();
  const downline = useAppSelector((state) => state.business.downline);

  useEffect(() => {
    console.log('In useBonuses useEffect', groupPv);

    if (groupPv < 600) dispatch(setIsBronzeFoundationActive(false));

    if (groupPv < 2500) dispatch(setIsBronzeBuilderActive(false));
  }, [groupPv, dispatch]);

  const downlineAtThreePercent = downline.filter((el) => {
    return el.groupPv >= 100;
  });

  const downlineAtSixPercent = downline.filter((el) => {
    return el.groupPv >= 300;
  });

  const isBronzeFoundationEnabled =
    groupPv >= 600 && downlineAtThreePercent.length >= 3;
  const isBronzeBuilderEnabled =
    groupPv >= 2500 && downlineAtSixPercent.length >= 3;

  return { isBronzeFoundationEnabled, isBronzeBuilderEnabled };
}

export default useBonuses;
