import ReactGA from 'react-ga4';
import useGroupStats from './useGroupStats';
import useCalculations from './useCalculations';
import { useAppSelector } from '../app/store';

export enum AnalyticsCategories {
  Business = 'Business',
  SavedHistory = 'SavedHistory',
  Bonuses = 'Bonuses',
}

export enum Properties {
  category = 'category',
  action = 'action',
  value = 'value',
  previousValue = 'previousValue',
  totalGroupPv = 'totalGroupPv',
  numOfDownline = 'numOfDownline',
  index = 'index',
}

// TODO: update category, action, and label to be an enum!
const useAnalyticsEventTracker = (category: AnalyticsCategories) => {
  const groupStats = useGroupStats();
  const calcs = useCalculations();
  const numOfSavedHistories = useAppSelector(
    (state) => state.business.savedConfigurations.length
  );

  let defaultProperties: Record<string, any> = {};

  defaultProperties.category = category;

  switch (category) {
    case AnalyticsCategories.Business:
      defaultProperties.totalGroupPv = groupStats.groupPv;
      defaultProperties.numOfDownline = groupStats.numOfDownline;
      Object.assign(calcs, defaultProperties);
      break;
    case AnalyticsCategories.SavedHistory:
      defaultProperties.numOfSavedHistories = numOfSavedHistories;
      break;

    default:
      break;
  }

  const eventTracker = (event: string, customObject: Record<string, any>) => {
    ReactGA.event(event, { ...defaultProperties, ...customObject });
  };

  return eventTracker;
};

export default useAnalyticsEventTracker;
