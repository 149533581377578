import { useAppSelector } from '../app/store';
import { getPerformanceBonus } from '../utils/helperFunctions';
import { calculateGroupPv } from '../utils/stats';

export default function useGroupStats() {
  const me = useAppSelector((state) => state.business.me);
  const downline = useAppSelector((state) => state.business.downline);
  const downlineGroupPv = downline.map((d) => d.groupPv);

  const numOfDownline = downline.length;
  const groupPv = calculateGroupPv(me.personalPv + me.vcsPv, downlineGroupPv);
  const performanceBonus = getPerformanceBonus(groupPv);

  return { numOfDownline, groupPv, performanceBonus };
}
