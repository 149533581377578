import {
  Button,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  resetCurrentConfiguration,
  saveCurrentConfiguration,
  removeConfiguration,
  setSelectedConfiguration,
  backToCurrentConfiguration,
  updateConfigurationName,
  setShowEditSavedHistoryName,
} from '../app/businessSlice';
import { useAppDispatch, useAppSelector } from '../app/store';
import DeleteIcon from '@mui/icons-material/Delete';
import { currency } from '../utils/helperFunctions';
import useCalculations from '../hooks/useCalculations';
import useGroupStats from '../hooks/useGroupStats';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useAnalyticsEventTracker, {
  AnalyticsCategories,
} from '../hooks/useAnalytics';

type SavedHistoryProps = {
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
};

export default function SavedHistory(props: SavedHistoryProps) {
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useAppDispatch();
  const [currentStructureName, setCurrentStructureName] = useState('');
  const [currentStructureIncome, setCurrentStructureIncome] = useState(0);
  const [savedConfigurationIndexToEdit, setSavedConfigurationIndexToEdit] =
    useState<number | null>(null);
  const [newConfigName, setNewConfigName] = useState('');
  const { totalIncome } = useCalculations();
  const { groupPv, numOfDownline: downlineCount } = useGroupStats();

  const showEditSavedHistoryName = useAppSelector(
    (state) => state.business.showEditSavedHistoryName
  );
  const savedHistories = useAppSelector(
    (state) => state.business.savedConfigurations
  );
  const selectedConfigurationId = useAppSelector(
    (state) => state.business.selectedConfigurationId
  );

  useEffect(() => {
    if (selectedConfigurationId !== null) return;

    setCurrentStructureIncome(totalIncome);
    setCurrentStructureName(`${downlineCount} legs / ${groupPv}PV`);
  }, [selectedConfigurationId, totalIncome, downlineCount, groupPv]);

  const savedHistoryEventTracker = useAnalyticsEventTracker(
    AnalyticsCategories.SavedHistory
  );

  return (
    <>
      <Stack
        width={{
          md: '20%',
          lg: '18%',
          xl: '15%',
        }}
        height="100vh"
        bgcolor="#3b3939"
        pt={2}
      >
        <Box>
          <Typography variant="h6" pl={1}>
            Current
          </Typography>
          <Box maxWidth="50%">
            <Divider />
          </Box>

          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                color="error"
                disabled={!!selectedConfigurationId}
                onClick={() => {
                  savedHistoryEventTracker('Delete', { id: 'Current' });

                  dispatch(resetCurrentConfiguration());
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton
              selected={!selectedConfigurationId}
              onClick={() => {
                if (!selectedConfigurationId) return;
                savedHistoryEventTracker('ChangeSelection', {
                  id: selectedConfigurationId,
                  value: 'Current',
                });
                dispatch(backToCurrentConfiguration());
                if (props.setIsOpen) props.setIsOpen(false);
              }}
            >
              <IconButton
                edge="start"
                disabled={!!selectedConfigurationId}
                onClick={() => {
                  savedHistoryEventTracker('Save', {});
                  dispatch(saveCurrentConfiguration());
                }}
              >
                <AddIcon />
              </IconButton>
              <Tooltip
                title={`The current configuration which has not been saved yet`}
              >
                <ListItemText
                  primary={currentStructureName}
                  secondary={currency(currentStructureIncome)}
                  secondaryTypographyProps={{
                    color: '#3e9c35',
                    fontWeight: 'bold',
                  }}
                />
              </Tooltip>
            </ListItemButton>
          </ListItem>
        </Box>

        <Box padding={2} />

        <Box>
          <Typography variant="h6" pl={1}>
            Saved
          </Typography>
          <Box maxWidth="50%">
            <Divider />
          </Box>

          <List>
            {savedHistories.map((savedItem, index) => (
              <Box key={savedItem.id}>
                <Tooltip title={`Added ${moment(savedItem.savedAt).fromNow()}`}>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        color="error"
                        onClick={(e) => {
                          e.preventDefault();
                          savedHistoryEventTracker('Delete', {
                            index,
                            name: savedItem.name,
                            savedAt: savedItem.savedAt,
                            id: savedItem.id,
                            totalIncome: savedItem.totalIncome,
                          });
                          dispatch(removeConfiguration({ id: savedItem.id }));
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      selected={selectedConfigurationId === savedItem.id}
                      onClick={() => {
                        savedHistoryEventTracker('ChangeToHistory', {
                          index,
                          name: savedItem.name,
                          savedAt: savedItem.savedAt,
                          id: savedItem.id,
                          totalIncome: savedItem.totalIncome,
                        });
                        dispatch(setSelectedConfiguration({ index }));

                        console.log('checking for setIsOpen:', props);
                        if (props.setIsOpen !== undefined) {
                          console.log('closing the drawer...');
                          props.setIsOpen(false);
                        }
                      }}
                    >
                      <IconButton
                        disabled={!isDesktopScreen}
                        edge="start"
                        onClick={(e) => {
                          e.preventDefault();

                          savedHistoryEventTracker('EditNameDialog', {
                            action: 'open',
                          });

                          dispatch(setShowEditSavedHistoryName(true));
                          setSavedConfigurationIndexToEdit(index);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <ListItemText
                        primary={savedItem.name}
                        secondary={currency(savedItem.totalIncome)}
                        secondaryTypographyProps={{
                          color: '#3e9c35',
                          fontWeight: 'bold',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
                <Divider />
              </Box>
            ))}
          </List>
        </Box>
      </Stack>

      <Dialog
        open={showEditSavedHistoryName}
        onClose={() => {
          savedHistoryEventTracker('EditNameDialog', { action: 'close' });
          dispatch(setShowEditSavedHistoryName(false));
        }}
      >
        <DialogTitle>Update Name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the name for the saved configuration. Note: this will
            overwrite the current name.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            placeholder={
              savedConfigurationIndexToEdit !== null
                ? savedHistories[savedConfigurationIndexToEdit].name
                : ''
            }
            value={newConfigName}
            onChange={(e) => {
              setNewConfigName(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!newConfigName}
            onClick={() => {
              savedHistoryEventTracker('EditNameDialog', {
                action: 'save',
                value: newConfigName,
                previousValue:
                  savedConfigurationIndexToEdit !== null
                    ? savedHistories[savedConfigurationIndexToEdit].name
                    : '',
              });
              dispatch(
                updateConfigurationName({
                  name: newConfigName,
                  index: savedConfigurationIndexToEdit,
                })
              );
              setSavedConfigurationIndexToEdit(null);
              setNewConfigName('');
              dispatch(setShowEditSavedHistoryName(false));
            }}
          >
            Update
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              savedHistoryEventTracker('EditNameDialog', { action: 'cancel' });
              dispatch(setShowEditSavedHistoryName(false));
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
