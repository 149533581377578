import { formatValue } from 'react-currency-input-field';

export const getPerformanceBonus = (pv: string | number) => {
  const pvNum = parseFloat(pv + '');

  if (pvNum < 100) return 0;
  if (pvNum < 300) return 3;
  if (pvNum < 600) return 6;
  if (pvNum < 1000) return 9;
  if (pvNum < 1500) return 12;
  if (pvNum < 2500) return 15;
  if (pvNum < 4000) return 18;
  if (pvNum < 6000) return 21;
  if (pvNum < 7500) return 23;

  return 25;
};

export const currency = (value: number) =>
  formatValue({
    value: value.toString(),
    groupSeparator: ',',
    decimalScale: 2,
    decimalSeparator: '.',
    prefix: '$',
  });
