import { Bonuses, Downline } from '../app/businessSlice';
import { getPerformanceBonus } from './helperFunctions';
import { AVG_PV_BV_RATIO } from '../utils/constants';

export const calculateGroupPv = (
  myPersonalPv: number,
  downlineGroupPv: number[]
) => {
  const total = downlineGroupPv.reduce(
    (runningCount, groupPv) => runningCount + groupPv,
    myPersonalPv
  );

  return total;
};

export const getPersonalBonus = (
  personalBv: number,
  performanceBonus: number
) => (personalBv * performanceBonus) / 100;

export const getRetailProfit = (vcsPv: number) => {
  const retailBv = (vcsPv * AVG_PV_BV_RATIO) / 0.9;
  const retailProfit = retailBv * 0.1;
  return retailProfit;
};

export const getProfit = (
  personalBv: number,
  vcsPv: number,
  performanceBonus: number,
  downline: Downline[],
  bonuses: Bonuses
) => {
  const personalBonus = getPersonalBonus(personalBv, performanceBonus);
  const retailProfit = getRetailProfit(vcsPv);

  const differentialBonuses = downline.map((d) => {
    const downlinePerformanceBonus = getPerformanceBonus(d.groupPv);
    const percentage = (performanceBonus - downlinePerformanceBonus) / 100;
    const differentialBonus = percentage * d.groupBv;

    return { name: d.name, groupPv: d.groupPv, bonus: differentialBonus };
  });
  const totalDifferentialBonus = differentialBonuses.reduce(
    (sum, d) => sum + d.bonus,
    0
  );

  const totalBeforeBonuses =
    personalBonus + retailProfit + totalDifferentialBonus;

  const bronzeFoundationBonus = bonuses.isBronzeFoundationActive
    ? totalBeforeBonuses * 0.3
    : 0;
  const bronzeBuilderBonus = bonuses.isBronzeBuilderActive
    ? totalBeforeBonuses * 0.4
    : 0;
  const totalBonus = bronzeFoundationBonus + bronzeBuilderBonus;

  const totalIncome =
    personalBonus + retailProfit + totalDifferentialBonus + totalBonus;

  return {
    personalBonus,
    retailProfit,
    totalDifferentialBonus,
    differentialBonuses,
    bronzeFoundationBonus,
    bronzeBuilderBonus,
    totalBonus,
    totalIncome,
  };
};
