import { useState } from 'react';
import { useAppDispatch } from '../app/store';
import ReactGA from 'react-ga4';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { setDownline, removeDownline } from '../app/businessSlice';
import useAnalyticsEventTracker, {
  AnalyticsCategories,
} from '../hooks/useAnalytics';

const getNextAndPrevBracket = (currentPv: number) => {
  if (currentPv < 150) return { nextBracket: 150, prevBracket: 0 };
  if (currentPv < 300) return { nextBracket: 300, prevBracket: 0 };
  if (currentPv < 600) return { nextBracket: 600, prevBracket: 150 };
  if (currentPv < 1000) return { nextBracket: 1000, prevBracket: 300 };
  if (currentPv < 1500) return { nextBracket: 1500, prevBracket: 600 };
  if (currentPv < 2500) return { nextBracket: 2500, prevBracket: 1000 };
  if (currentPv < 4000) return { nextBracket: 4000, prevBracket: 1500 };
  if (currentPv < 6000) return { nextBracket: 6000, prevBracket: 2500 };
  if (currentPv < 7500) return { nextBracket: 7500, prevBracket: 4000 };
  else return { nextBracket: 15000, prevBracket: 6000 };
};

type DownlineMoreButtonProps = {
  index: number;
  groupPv: number;
};

export default function DownlineMoreButton({
  index,
  groupPv,
}: DownlineMoreButtonProps) {
  const dispatch = useAppDispatch();

  const businessEventTracker = useAnalyticsEventTracker(
    AnalyticsCategories.Business
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { nextBracket, prevBracket } = getNextAndPrevBracket(groupPv);

  return (
    <>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            businessEventTracker('ChangePvButton', {
              index,
              action: 'Up',
              value: nextBracket,
            });

            dispatch(
              setDownline({
                index: index,
                property: 'groupPv',
                value: nextBracket,
              })
            );
            handleClose();
          }}
        >
          <ListItemIcon>
            <ArrowUpwardIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Up to {nextBracket}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            businessEventTracker('ChangePvButton', {
              index,
              action: 'Down',
              value: prevBracket,
            });
            dispatch(
              setDownline({
                index: index,
                property: 'groupPv',
                value: prevBracket,
              })
            );
            handleClose();
          }}
        >
          <ListItemIcon>
            <ArrowDownwardIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Down to {prevBracket}</ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => {
            businessEventTracker('DeleteDownline', {
              index,
              groupPv,
            });

            dispatch(removeDownline(index));
            handleClose();
          }}
          sx={{
            color: 'red',
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
