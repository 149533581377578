import {
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
} from '@mui/material';
import useBonuses from '../hooks/useBonuses';
import { bronzeColor } from '../utils/theme';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleBronzeBuilder,
  toggleBronzeFoundation,
} from '../app/businessSlice';
import PaidIcon from '@mui/icons-material/Paid';
import useAnalyticsEventTracker, {
  AnalyticsCategories,
} from '../hooks/useAnalytics';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function MobileBronzeSwitches() {
  const dispatch = useDispatch();
  const { isBronzeFoundationEnabled, isBronzeBuilderEnabled } = useBonuses();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isBronzeFoundationActive = useSelector(
    (state: any) => state.business.bonuses.isBronzeFoundationActive
  );
  const isBronzeBuilderActive = useSelector(
    (state: any) => state.business.bonuses.isBronzeBuilderActive
  );

  const [isBronzeFoundationTooltipOpen, setIsBronzeFoundationTooltipOpen] =
    useState(false);
  const [isBronzeBuilderTooltipOpen, setIsBronzeBuilderTooltipOpen] =
    useState(false);

  const bonusesEventTracker = useAnalyticsEventTracker(
    AnalyticsCategories.Bonuses
  );

  const handleBronzerFoundationToggle = () => {
    bonusesEventTracker('BronzeFoundationHelp', { action: 'open' });
    setIsBronzeFoundationTooltipOpen(!isBronzeFoundationTooltipOpen);
  };
  const handleBronzerBuilderToggle = () => {
    bonusesEventTracker('BronzeFoundationHelp', { action: 'open' });
    setIsBronzeBuilderTooltipOpen(!isBronzeBuilderTooltipOpen);
  };

  return (
    <>
      <IconButton
        size="large"
        onClick={() => {
          bonusesEventTracker('BronzeDialog', { action: 'open' });
          setIsDialogOpen(true);
        }}
      >
        <Typography color={bronzeColor} marginRight={1}>
          BRONZE
        </Typography>
        <PaidIcon sx={{ color: bronzeColor }} />
      </IconButton>

      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={() => {
          bonusesEventTracker('BronzeDialog', { action: 'close' });
          setIsDialogOpen(false);
        }}
      >
        <DialogTitle>
          <Typography
            variant="h4"
            sx={{ textAlign: 'center', color: bronzeColor }}
          >
            Bronze Incentives
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" justifyContent="space-between">
            <FormGroup>
              <FormControlLabel
                label="Foundation"
                control={
                  <Switch
                    disabled={!isBronzeFoundationEnabled}
                    onChange={() => {
                      bonusesEventTracker('ToggleBronzeFoundation', {
                        value: isBronzeFoundationActive
                          ? 'Disabled'
                          : 'Enabled',
                      });
                      dispatch(toggleBronzeFoundation());
                    }}
                    checked={isBronzeFoundationActive}
                  />
                }
              />
            </FormGroup>

            <IconButton onClick={handleBronzerFoundationToggle}>
              <HelpOutlineIcon />
            </IconButton>
          </Stack>

          {isBronzeFoundationTooltipOpen && (
            <Typography variant="caption" paddingBottom={1}>
              You must have 3 legs at 150 PV AND 600 GPV!
            </Typography>
          )}

          <Stack direction="row" justifyContent="space-between">
            <FormGroup>
              <FormControlLabel
                label="Builder"
                control={
                  <Switch
                    disabled={!isBronzeBuilderEnabled}
                    onChange={() => {
                      bonusesEventTracker('ToggleBronzeBuilder', {
                        value: isBronzeBuilderActive ? 'Disabled' : 'Enabled',
                      });
                      dispatch(toggleBronzeBuilder());
                    }}
                    checked={isBronzeBuilderActive}
                  />
                }
              />
            </FormGroup>

            <IconButton onClick={handleBronzerBuilderToggle}>
              <HelpOutlineIcon />
            </IconButton>
          </Stack>

          {isBronzeBuilderTooltipOpen && (
            <Typography variant="caption" paddingBottom={1}>
              You must have 3 legs at 300 PV AND 2500 GPV!
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
